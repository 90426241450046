let origin = "https://future.zhuneng.cn";
//查询是否有权限查看监控
const lookPower = `/pubApi/external/nursery/getVideoData`;
//提交申请
const postApply = `/pubApi/external/nursery/nurseryApply`;
//小和托幼申请列表
const entrustChildUrl = `/pubApi/external/nursery/getApplyData`;
//审核申请
const handelStatusUrl = `/pubApi/external/nursery/doExamine`;
//查询课程
const selectCourseUrl = `/pubApi/external/nursery/getClassData`;
//查询个人申请列表
const selectMyApplyUrl = `/pubApi/external/nursery/getUserApplyData`;
//查询全部待申请列表
const selectAllApplyUrl = `/pubApi/external/nursery/getApplyData`;
//审核申请
const judgeApplyUrl = `/pubApi/external/nursery/doExamine`;

export {
  lookPower,
  postApply,
  entrustChildUrl,
  handelStatusUrl,
  selectCourseUrl,
  selectMyApplyUrl,
  selectAllApplyUrl,
  judgeApplyUrl,
};
